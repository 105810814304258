import { useRefs } from "./../framework";

const clamp = (min, val, max) => Math.max(min, Math.min(max, val));

export default (ref) => {
  if (!ref.announcementBar) return;

  const instances = ref.announcementBar.map((element) => {
    return useRefs({ root: element, namespaced: true, asArray: true });
  });

  let announcementBarsHeight = instances.reduce((acc, cur) => {
    const { height } = cur.root.getBoundingClientRect();
    return acc + height;
  }, 0);

  function moveHeaderToBelowAnnouncementBar() {
    const value = window.scrollY * -1 + announcementBarsHeight;
    ref.header[0].style.transform = `matrix(1, 0, 0, 1, 0, ${clamp(0, value, announcementBarsHeight)})`;
  }

  moveHeaderToBelowAnnouncementBar();

  window.addEventListener('scroll', () => {
    moveHeaderToBelowAnnouncementBar();
  })
};