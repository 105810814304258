import { setTransition } from "./../framework";
import barba from "@barba/core";

export default (ref) => {
  if (!ref.productSorting) return;

  let sorting = null;
  let searchParamsInitial = window.location.search.slice(1);
  let prevSearchParams = window.location.search.slice(1);

  const handleInputChange = (e) => {
    sorting = e.target.value;

    const searchParams = createSearchParams(e.target.form);
    renderPage(searchParams, null);
  };

  const createSearchParams = (form) => {
    const formData = new FormData(form);

    return new URLSearchParams(formData).toString();
  };

  const onHistoryChange = (e) => {
    const searchParams = e.state ? e.state.searchParams : searchParamsInitial;

    if (searchParams === prevSearchParams) return;

    renderPage(searchParams, null, false);
  };

  const updateURLHash = (searchParams) => {
    history.pushState(
      { searchParams },
      "",
      `${window.location.pathname}${searchParams && "?".concat(searchParams)}`,
    );
  };

  const renderPage = (searchParams, event, updateURL = true) => {
    prevSearchParams = searchParams;

    ref.productGrid.forEach((section) => {
      const url = `${
        window.location.pathname
      }?section_id=${section.dataset.id}&${searchParams}`;

      renderSectionFromFetch(url, event);
    });

    if (updateURL) {
      updateURLHash(searchParams);
    }
  };

  const renderSectionFromFetch = async (url, event) => {
    const response = await fetch(url);
    const responseText = await response.text();
    const html = responseText;

    const target = document.getElementById("product-grid");

    if (!target) return;

    target.innerHTML = new DOMParser()
      .parseFromString(html, "text/html")
      .getElementById("product-grid").innerHTML;
  };

  ref.productSorting.forEach((element) => {
    if (element.tagName === "SELECT") {
      element.addEventListener("change", handleInputChange);
    } else {
      const buttons = element.querySelectorAll("button");
      buttons.forEach((button) => {
        button.addEventListener("click", () => {
          const { value } = button.dataset;
          const currentParams = new URLSearchParams(window.location.search);

          currentParams.set("sort_by", value);

          setTransition("nothing");

          barba.go(
            window.location.origin +
              window.location.pathname +
              "?" +
              currentParams.toString(),
          );
        });
      });
    }
  });

  // window.addEventListener("popstate", onHistoryChange);
};
