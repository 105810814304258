import { intersection } from "./../framework";

export default ref => {
  if (!ref.productForm) return;

  let observer;
  let footerVisible = false;

  const BTN_LABELS = {
    addToCart: 'add-to-cart',
    soldOut: 'sold-out',
  }

  ref.productForm.forEach(form => {
    const sizeInputs = form.querySelectorAll('input[name="size"]');
    const colorInputs = form.querySelectorAll('input[name="color"]');
    const formBtnLabels = form.querySelectorAll('[data-label]');
    const barBtnLabels = ref.buyBar[0].querySelectorAll('[data-label]');
    const btnLabels = [...formBtnLabels].concat([...barBtnLabels]);
    const optionGroups = ref.variantSelect[0].dataset.options.split(',');
    const variantOptions = ref.variantSelect[0].getElementsByTagName('option');
    
    const getSelectedOptions = () => {
      const selectedColor = form.querySelector('input[name="color"]:checked')?.value;
      const selectedSize = form.querySelector('input[name="size"]:checked')?.value;
      
      return { selectedColor, selectedSize };
    }
    
    const updateButton = (disabled, label) => {
      ref.addToCartBtn.forEach(btn => btn.disabled = disabled)
      ref.addToCartBtn.forEach(btn => btn.dataset.state = BTN_LABELS.addToCart)
      
      btnLabels.forEach(element => {
        element.classList.toggle('hidden', element.dataset.label != label)
      });
    }

    const handleOptionChange = () => {
      const { selectedSize, selectedColor } = getSelectedOptions();

      const selectedOptions = {
        'color': selectedColor,
        'size': selectedSize
      }
      
      const variantTitle = optionGroups.map(option => selectedOptions[option.toLowerCase()]).join(' / ');;
      const selectedOption = [...variantOptions].find(option => option.innerText.includes(variantTitle))
      const selectedIndex = [...variantOptions].findIndex(option => option == selectedOption)

      ref.variantSelect.forEach(select => select.selectedIndex = selectedIndex)

      if(selectedOption.dataset.available == "false") {
        updateButton(true, BTN_LABELS.soldOut);
      } else {
        updateButton(false, BTN_LABELS.addToCart);
      }
    }

    colorInputs.forEach(input => input.addEventListener('change', handleOptionChange));
    sizeInputs.forEach(input => input.addEventListener('change', handleOptionChange));
  });

  const handleScroll = () => {
    ref.buyBar[0].classList.toggle('translate-y-[150%]', footerVisible || window.scrollY < window.innerHeight);
  }
  
  const handleIntersection = ([entry]) => {
    footerVisible = entry.isIntersecting
  }

  if (ref.footer) {
    observer = intersection(ref.footer[0], handleIntersection, {
      threshold: [0]
    });
  }
  
  window.addEventListener('scroll', handleScroll)

  return () => {
    window.removeEventListener('scroll', handleScroll)
  };
};
