import { useRefs } from "./../framework";
import { breakpoints } from "./../framework";

export default ref  => {
  if (!ref.mouseFollow) return;

  const isMobile = () => window.innerWidth < breakpoints.sm;


  ref.mouseFollow.forEach(el => {
    if(!isMobile()) {
      const refs = useRefs({ root: el, namespaced: true });
  
      const handleCursorMove = (e) => {
        const rect = el.getBoundingClientRect();
            
        const x = e.clientX - rect.left;
        const y = e.clientY - rect.top;
    
        refs.tag.style.left = `${x}px`;
        refs.tag.style.top = `${y}px`;
      }
  
      el.addEventListener('mousemove', handleCursorMove);
    }
  });
} 
