export default {
  enter(_, oldScrollY) {
    const rollUpLayers = document.querySelectorAll('[data-ref="animate-roll-up"]')
    const rollUpChildLayers = document.querySelectorAll('[data-ref-animate-reveal-items="items"]')

    rollUpLayers.forEach(el => el?.classList.add('hidden', 'lg:hidden'))
    rollUpChildLayers.forEach(el => el?.classList.add('hidden', 'lg:hidden'))

    window.scrollTo(0, oldScrollY)

    return true
  }
}