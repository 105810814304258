export default ref => {
  if (!ref.overlay) return;
  const toggleOverlay = (e) => {

    e.preventDefault()
    e.stopPropagation()

    const overlayType = e.currentTarget.dataset.toggle;
    const overlay = [...ref.overlay].find(el => el.dataset.type == overlayType);
    const elements = [overlay].concat(ref.overlayBg);
    const content = overlay.querySelector('[data-ref="overlay-content"]')
    const scrollLocked = document.body.style.overflowY == 'hidden'
    
    elements.forEach(element => {
      const hidden = element.getAttribute("aria-hidden") === "true";
      
      if(element.dataset.opacity) {
        element.classList.add(element.dataset.opacity);
      } else {
        element.classList.remove("opacity-0");
      }
      if(element.dataset.ref != "overlay") {
        element.classList.toggle("pointer-events-none");
      }
      element.setAttribute("aria-hidden", !hidden);
    });
    
    content.classList.toggle("translate-x-0");
    content.classList.toggle("translate-x-full");

    handleBodyScroll(!scrollLocked)
    console.log('body overflow y', document.body.style.overflowY)
  };

  const closeOverlay = () => {
    handleBodyScroll(false);

    const overlay = [...ref.overlay].find(el => el.ariaHidden == 'false');
    const elements = [overlay].concat(ref.overlayBg);
    const content = overlay.querySelector('[data-ref="overlay-content"]')

    elements.forEach(element => {
      if(element.dataset.opacity) {
        element.classList.remove(element.dataset.opacity);
      } else {
        setTimeout(() => {
        element.classList.add("opacity-0");
      }, 500)
      }
      
      if(element.dataset.ref != "overlay") {
        element.classList.toggle("pointer-events-none");
      }

      element.setAttribute("aria-hidden", true);
      
    });

    content.classList.remove("translate-x-0");
    content.classList.add("translate-x-full");
  };

  const handleKeyDown = e => {
    if (e.which === 27) {
      closeOverlay();
    }
  };

  const handleBodyScroll = (lock) => {
    document.body.style.overflowY = lock ? 'hidden' : 'auto';    
  }

  ref.overlayBg?.forEach(el => el.addEventListener("click", closeOverlay));
  ref.overlayClose?.forEach(btn => btn.addEventListener("click", closeOverlay));
  ref.overlayToggle?.forEach(toggle => toggle.addEventListener("click", toggleOverlay));

  window.addEventListener("keydown", handleKeyDown);
};
