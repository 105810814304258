import { useRefs } from './../framework'

export default ref => {
  if(!ref.predictiveSearch || !ref.predictiveSearchTrigger) return

  const cachedResults = new Map()
  let abortController = new AbortController()
  
  let isOpen = false

  const instances = ref.predictiveSearch.map(element => useRefs({ root: element, namespaced: true }))

  instances.forEach(instance => {
    // make search on input
    instance.input.addEventListener('input', () => {
      search(instance.input.value)
    })

    // close on backdrop-click
    instance.root.addEventListener('click', (e) => {
      const { height } = instance.root.getBoundingClientRect()
      if(e.offsetY > height) toggleSearchOverlay(false)
    })
  })
  
  // close on escape-click
  window.addEventListener('keydown', (e) => {
    if(e.key === 'Escape') toggleSearchOverlay(false)
  })

  // toggle open/close on trigger click
  ref.predictiveSearchTrigger.forEach(button => {
    button.addEventListener('click', () => {
      toggleSearchOverlay()
    })
  })

  // close on page-shift
  window.addEventListener('window.navigation', () => toggleSearchOverlay(false))

  /**
   * functions
   */
  function toggleSearchOverlay(toOpen?: boolean) {
    const willOpen = toOpen === undefined ? !isOpen : toOpen
    
    instances.forEach(instance => {
      // open classes
      instance.root.classList.toggle('md:opacity-100', willOpen)
      instance.root.classList.toggle('md:pointer-events-none', willOpen)
      
      // closed classes
      instance.root.classList.toggle('md:opacity-0', !willOpen)
      instance.root.classList.toggle('md:pointer-events-none', !willOpen)

      isOpen = willOpen

      if(willOpen) {
        instance.input.focus()
      } else {
        instance.input.value = ''
        search('')
      }
    })
  }

  async function search(searchTerm) {
    try {
      const baseUrl = instances[0].root.dataset.predictiveSearchUrl
      const queryKey = searchTerm.replace(' ', '-').toLowerCase();

      if(!queryKey) {
        renderSearchResults('');
        return
      }
      
      if (cachedResults.get(queryKey)) {
        renderSearchResults(cachedResults.get(queryKey));
        return;
      }
      
      // LOADING = TRUE

      abortController.abort();
      abortController = new AbortController();

      const response = await fetch(`${baseUrl}?q=${encodeURIComponent(searchTerm)}&section_id=predictive-search-results&resources[type]=product&resources[limit]=4`, {
        signal: abortController.signal,
      })

      if (!response.ok) {
        var error = new Error(response.status.toString())
        throw error
      }

      const text = await response.text()
      const markup = new DOMParser().parseFromString(text, 'text/html')

      const resultsMarkup = markup.querySelector('#shopify-section-predictive-search-results').innerHTML
      
      renderSearchResults(resultsMarkup)

      cachedResults.set(queryKey, resultsMarkup)

      // LOADING = FALSE
    } catch(error) {
      // Code 20 means the call was aborted
      if (error?.code === 20) return
      
      console.log(error)
    }
  }

  function renderSearchResults(markup) {
    instances.forEach(instance => instance.resultList.innerHTML = markup)
  }
}