import { progress } from './../framework/use-progress.js';

export default (ref, { root = document } = {})  => {
  const parallaxItems = Array.from(root.querySelectorAll('[data-ref="parallax-el"]'));

  if (!parallaxItems.length) return;

  const init = () => {
    parallaxItems.forEach(item => {
      progress.add({
        element: item,
        value: parseFloat(item.dataset.parallax || -150),
        xOffset: 0
      });
    })
  }

  init();
}