import { useRefs, useHydrate } from "./../framework";
import useALaCart from "~/modules/a-la-cart";
import { components } from "~/components";

async function fetchCartAmount() {
  try {
    const res = await fetch("/cart.js");
    const json = await res.json();
    if (json?.item_count !== undefined) return json.item_count;

    throw "did not receive amount from shopify :(";
  } catch (error) {
    window.location.reload();
    return "";
  }
}

// update text label indicating current amount in cart
export const cartItemCount = (ref) => {
  if (!ref.cartItemCount) return;

  async function updateAmount(e) {
    // const amountFromEvent = e.detail?.items?.reduce(
    //   (acc, cur) => (acc += cur.quantity),
    //   0,
    // );

    // const amount = amountFromEvent || (await fetchCartAmount());
    const amount = await fetchCartAmount();

    ref.cartItemCount.forEach((el) => (el.textContent = amount));
  }

  window.addEventListener("a-la-cart.product-added", updateAmount);
  window.addEventListener("a-la-cart.cart-updated", updateAmount);
};

// handle cart opening/closing
export default (ref) => {
  if (!ref.cart) return;

  const [cart] = ref.cart;

  function hydrateCart() {
    const refs = useRefs({ root: cart, asArray: true });

    console.log(refs)

    const content = ref.cart[0].querySelector("[data-shopify-content]");
    content.classList.add('no-scrollbar')
    content.style.overflowY = 'scroll'
    content.style.maxHeight = 'calc(100vh - 230px)'
    content.style.overscrollBehavior = 'contain'

    useALaCart({
      useBarbaNavigation: true,
      drawerContainer: "[data-cart-drawer-content]",
      cartSectionSelector: "[data-shopify-content]",
    });

    useHydrate(components).hydrate(refs);

    refs.closeButton[0]?.addEventListener("click", () => {
      window.dispatchEvent(new CustomEvent("a-la-cart.close-drawer"));
    });
  }

  window.addEventListener("a-la-cart.drawer-opened", hydrateCart);
  window.addEventListener("a-la-cart.cart-updated", hydrateCart);

  window.addEventListener("a-la-cart.product-added", () => {
    window.dispatchEvent(new CustomEvent("a-la-cart.open-drawer"));
  });

  function setLoadingState(e) {
    const isLoading = e.detail;
    const form = cart.querySelector("#cartItems");
    form.style.opacity = isLoading ? "0.5" : null;
  }

  window.addEventListener("a-la-cart.is-updating", setLoadingState);

  // close on backdrop click
  cart.addEventListener("click", (e) => {
    if (e.target.closest("[data-cart-drawer-content]")) return;

    window.dispatchEvent(new CustomEvent("a-la-cart.close-drawer"));
  });
};
