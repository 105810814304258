import {useRefs} from './../framework'

const lerp = (start, end, amt) => (1-amt)*start+amt*end;
const clamp = (min, val, max) => Math.min(max,Math.max(min, val))

export default ref => {
  if(!ref.hero) return

  const hero = useRefs({ root: ref.hero[0], namespaced: true})
  const header = useRefs({ root: ref.header[0], namespaced: true})

  hero.logo.style.transformOrigin = 'top'

  let initial = hero.logo.getBoundingClientRect();
  let target = header.logo.firstElementChild.getBoundingClientRect();
  let initialTop = initial.top + window.scrollY;

  function getValues () {
    initial = hero.logo.getBoundingClientRect()
    target = header.logo.firstElementChild.getBoundingClientRect()
    initialTop = initial.top + window.scrollY
  }

  let running = true
  
  function scaleLogo() {
    if(window.location.pathname !== '/') return

    const targetScale = target.width / initial.width
    const progress = window.scrollY / (initialTop - target.top)
    const progressClamped = clamp(0, progress, 1)
    const scale = lerp(1, targetScale, progressClamped)

    // show/hide the "real" logo when hero logo is in it's place
    header.logo.firstElementChild.style.opacity = progress >= 1 ? '1' : '0'
    hero.logo.style.opacity = progress >= 1 ? '0' : '1'

    // scale the hero logo into the "real" logo's size
    hero.logo.style.transform = `matrix(${scale}, 0, 0, ${scale}, 0, 0)`
  }

  window.addEventListener('resize', getValues)
  window.addEventListener('scroll', scaleLogo)
  
  scaleLogo()
  
  return () => {
    running = false
    window.removeEventListener('scroll', scaleLogo)
    window.removeEventListener('resize', getValues)
    header.logo.firstElementChild.style.removeProperty('opacity')
  }
}