import filtering from "./filtering";
import parallax from "./parallax";
import accordion from "./accordion";
import header from "./header";
import countryLocalization from "./country-localization";
import productComplementary from "./product-complementary";
import productRecommendations from "./product-recommendations";
import embla from "./embla";
import dropdown from "./dropdown";
import productSorting from "./product-sorting";
import productGallery from "./product-gallery";
import productForm from "./product-form";
import cart, { cartItemCount } from "./cart";
import quantitySelector from "./quantity-selector";
import instagram from "./instagram";
import overlay from "./overlay";
import mouseFollowTag from "./mouse-follow-tag";
import loadMore from "./load-more";
import hero from "./hero";
import * as animate from "./animate";
import predictiveSearch from "./predictive-search";
import announcementBar from "./announcement-bar";
import setTransition from "./set-transition";
import embedVideo from "./embed-video";

// add all components that only needs to be initialized once
export const globals = [header, cart, cartItemCount, predictiveSearch, announcementBar];

// add all components that needs to be initialized after every page shift
export const components = [
  embedVideo,
  countryLocalization,
  embla,
  dropdown,
  productRecommendations,
  filtering,
  parallax,
  accordion,
  productComplementary,
  productSorting,
  productGallery,
  productForm,
  quantitySelector,
  instagram,
  overlay,
  mouseFollowTag,
  loadMore,
  hero,
  animate.items,
  animate.revealItems,
  animate.fadeIn,
  animate.scaleUp,
  animate.rollUp,
  setTransition
];

export const componentsAfterPageTransition = [
  
]
