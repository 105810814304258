import { useRefs } from "./../framework";
import { breakpoints } from "./../framework";

export default (ref) => {
  if (!ref.header) return;

  let timer = null;
  let wait = false;

  const isMobile = () => window.innerWidth < breakpoints.sm;
  const refs = useRefs({ root: ref.header[0], namespaced: true });

  const SUBMENU_OPEN_CLASSES = isMobile()
    ? ["text-black"]
    : ["bg-white", "text-black"];
  const SUBMENU_CLOSED_CLASSES = ["mix-blend-difference", "text-white"];

  const TOGGLE_STATES = {
    default: "default",
    back: "back",
    close: "close",
  };

  const handleBlendMode = (menuOpen) => {
    // if(wait) return

    SUBMENU_OPEN_CLASSES.forEach((className) =>
      ref.header[0].classList.toggle(className, menuOpen),
    );

    SUBMENU_CLOSED_CLASSES.forEach((className) =>
      ref.header[0].classList.toggle(className, !menuOpen),
    );

    const color = menuOpen ? "black" : "white";

    document.documentElement.style.setProperty("--line-color", color);
  };

  // For mobile menu
  const handleToggleMenu = () => {
    refs.menu.classList.toggle("-translate-x-full");

    const open = !refs.menu.classList.contains("-translate-x-full");
    const subMenuOpen = ref.subMenu.find(
      (menu) => menu.getAttribute("aria-expanded") === "true",
    );

    const delay = open ? 0 : 700;

    setTimeout(() => {
      handleBlendMode(open);
    }, delay);

    updateToggleState(
      open && !!subMenuOpen
        ? TOGGLE_STATES.back
        : open
          ? TOGGLE_STATES.close
          : TOGGLE_STATES.default,
    );

    if (isMobile()) {
      handleBodyScroll(open);
    }
  };

  // Update icon and functionality of the button/toggle - mobile
  const updateToggleState = (state) => {
    refs.menuToggle.dataset.state = state;

    ref.toggleIcon.forEach((icon) =>
      icon.classList.toggle("invisible", icon.dataset.icon != state),
    );
  };

  const setSubMenuState = (submenu, state) => {
    if (state) {
      submenu.classList.remove("md:opacity-0");
      submenu.classList.remove("md:pointer-events-none");
    } else {
      submenu.classList.add("md:opacity-0");
      submenu.classList.add("md:pointer-events-none");
    }

    submenu.setAttribute("aria-expanded", state);
    submenu.classList.toggle("max-md:-translate-x-full", !state);
    submenu.classList.toggle("max-md:translate-x-0", state);

    const { height } = submenu.getBoundingClientRect();

    document.body.style.setProperty("--current-submenu-height", height + "px");

    [...ref.subMenuBg].forEach((layer) => {
      const opacityClass = layer.dataset.opacity
      layer.classList.toggle(opacityClass, state);
    });
  };

  const handleExpandableItem = (currentTarget, state) => {
    if (wait) return;
    const submenu = ref.subMenu.find(
      (subMenu) => subMenu.dataset.expandedBy === currentTarget.dataset.expands,
    );

    setSubMenuState(submenu, state);
  };

  const handleToggleClick = (e) => {
    const currentState = refs.menuToggle.dataset.state;

    if (
      currentState == TOGGLE_STATES.default ||
      currentState == TOGGLE_STATES.close
    ) {
      handleToggleMenu();
    }

    /**
     * open submenu automatically, if it's child is active (typically collection-page)
     */
    const submenuWithActiveChild = ref.subMenu.find((submenu) => {
      return !!submenu.querySelector(".active");
    });

    if (
      isMobile() &&
      submenuWithActiveChild &&
      currentState === TOGGLE_STATES.default
    ) {
      setSubMenuState(submenuWithActiveChild, true);
      updateToggleState(TOGGLE_STATES.back);

      return;
    }

    if (currentState == TOGGLE_STATES.back) {
      const submenu = ref.subMenu.find(
        (menu) => menu.getAttribute("aria-expanded") === "true",
      );
      toggleSubMenu(submenu);
    }
  };

  const toggleSubMenu = (submenu) => {
    const expanded = submenu.getAttribute("aria-expanded") === "true";
    const state = expanded ? TOGGLE_STATES.close : TOGGLE_STATES.back;

    setSubMenuState(submenu, !expanded);
    updateToggleState(state);
  };

  const setActiveLink = async () => {
    try {
      const res = await fetch("?section_id=header");
      const text = await res.text();
      const parser = new DOMParser();
      const markup = parser.parseFromString(text, "text/html");

      const newActive = markup.querySelector(".active") as HTMLAnchorElement;
      const oldActives = [
        ...ref.header[0].parentElement.querySelectorAll(".active"),
      ];

      oldActives.forEach((link) => {
        link.classList.remove("active");
        link.classList.add("after:scale-x-50");
        link.classList.remove("after:scale-x-100");
        link.classList.remove("after:opacity-100");
        link.classList.add("after:opacity-0");
      });

      if (!newActive) return;

      const parent = newActive?.closest("[data-expanded-by]") as HTMLElement;

      const headerSections = [
        ...ref.header[0].parentElement.querySelectorAll("[data-expands]"),
      ];
      const headerSection = headerSections?.find(
        (section) => section.dataset.expands === parent?.dataset.expandedBy,
      );

      const activeParentLink = headerSection?.firstElementChild;
      const { pathname } = new URL(newActive.href);
      const targets = [
        ...ref.header[0].parentElement.querySelectorAll(`[href="${pathname}"]`),
      ];
      const allActive = [...targets].concat([activeParentLink]);

      allActive.forEach((item) => {
        item?.classList.add("active");
        item?.classList.add("after:scale-x-100");
        item?.classList.remove("after:scale-x-50");
        item?.classList.remove("after:opacity-0");
        item?.classList.add("after:opacity-100");
      });
    } catch (error) {
      console.warn(error);
    }
  };

  const handleParentClick = (e) => {
    if (e.target.tagName == "DIV") {
      const submenu = ref.subMenu.find(
        (subMenu) =>
          subMenu.dataset.expandedBy === e.currentTarget.dataset.expands,
      );

      toggleSubMenu(submenu);
    }
  };

  const closeMenu = () => {
    refs.menu.classList.add("-translate-x-full");

    ref.subMenu.forEach((menu) => {
      menu.setAttribute("aria-expanded", false);
      menu.classList.add("md:opacity-0");
      menu.classList.add("md:pointer-events-none");

      menu.classList.add("max-md:-translate-x-full");
      menu.classList.remove("max-md:translate-x-0");

      // menu.classList.add("md:hidden");
    });

    ref.subMenuBg.forEach((layer) => {
      const opacityClass = layer.dataset.opacity
      layer.classList.remove(opacityClass);
    });

    const delay = isMobile() ? 700 : 0;

    setTimeout(() => {
      handleBlendMode(false);
    }, delay);

    updateToggleState(TOGGLE_STATES.default);
  };

  const handleBodyScroll = (lock) => {
    document.body.style.overflowY = lock ? "hidden" : "auto";
  };

  window.addEventListener("window.navigation", () => {
    handleBodyScroll(false);
  });

  const handleRouteChange = () => {
    setActiveLink();
    closeMenu();

    wait = true;
    clearTimeout(timer);

    timer = setTimeout(() => {
      wait = false;
    }, 500);
  };

  const handleResize = () => {
    setEventListeners();
  };

  const setEventListeners = () => {
    ref.expandableMenuItem.forEach((item) => {
      if (window.innerWidth < breakpoints.md) {
        item.addEventListener("click", handleParentClick);
      } else {
        item.addEventListener("mouseenter", (e) =>
          handleExpandableItem(e.currentTarget, true),
        );
        item.addEventListener("mouseleave", (e) => {
          const elementUnderCursor = document.elementFromPoint(
            e.clientX,
            e.clientY,
          );
          const elementBelongsToTarget = elementUnderCursor?.closest(
            `[data-expanded-by="${e.target.dataset.expands}"]`,
          );

          if (!elementBelongsToTarget) {
            handleExpandableItem(e.currentTarget, false);
          }
        });
      }
    });

    ref.subMenu.forEach((item) => {
      item.addEventListener("mouseleave", (e) => {
        setSubMenuState(e.currentTarget, false);
      });
    });
  };

  refs.menuToggle.addEventListener("click", handleToggleClick);
  window.addEventListener("window.navigation", handleRouteChange);
  window.addEventListener("resize", handleResize);

  setEventListeners();
  setActiveLink();
};
