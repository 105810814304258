const addTogglePrevNextBtnsActive = (emblaApi, prevBtn, nextBtn) => {
  const togglePrevNextBtnsState = () => {
    if (prevBtn) {
      if (emblaApi.canScrollPrev()) prevBtn.removeAttribute("disabled");
      else prevBtn.setAttribute("disabled", "disabled");
    }

    if (nextBtn) {
      if (emblaApi.canScrollNext()) nextBtn.removeAttribute("disabled");
      else nextBtn.setAttribute("disabled", "disabled");
    }
  };

  emblaApi
    .on("select", togglePrevNextBtnsState)
    .on("init", togglePrevNextBtnsState)
    .on("reInit", togglePrevNextBtnsState);

  return () => {
    if (prevBtn) prevBtn.removeAttribute("disabled");
    if (nextBtn) nextBtn.removeAttribute("disabled");
  };
};

export const addPrevNextBtnsClickHandlers = (emblaApi, prevBtn, nextBtn) => {
  const scrollPrev = () => {
    emblaApi.scrollPrev();
  };
  const scrollNext = () => {
    emblaApi.scrollNext();
  };
  if (prevBtn) prevBtn.addEventListener("click", scrollPrev, false);
  if (nextBtn) nextBtn.addEventListener("click", scrollNext, false);

  const removeTogglePrevNextBtnsActive = addTogglePrevNextBtnsActive(
    emblaApi,
    prevBtn,
    nextBtn,
  );

  return () => {
    removeTogglePrevNextBtnsActive();
    if (prevBtn) prevBtn.removeEventListener("click", scrollPrev, false);
    if (nextBtn) nextBtn.removeEventListener("click", scrollNext, false);
  };
};
