/**
 * @function createDevGrid
 * @description Appends some fixed divs to the `<body>`, which are styled to look like a grid.
 * @param {object} [options] - Options object
 * @param {number} [options.cols] - Amount of columns
 * @param {string} [options.bleed] - Horizontal space outside the grid
 * @param {string} [options.gap] - Horizontal space between columns
 * @param {string} [options.color] - A CSS-hsl string defining the color of the grid
 */
export const createDevGrid = ({
  cols = 12,
  bleed = '32px',
  gap = '16px',
  color = '210 100% 56%',
} = {}) => {
  if (!location.search.includes('grid')) return

  const container = document.createElement('div')
  container.classList.add('dev-grid')

  Object.assign(container.style, {
    position: 'fixed',
    width: '100vw',
    height: '100vh',
    zIndex: '100000000',
    top: '0',
    left: '0',
    boxSizing: 'border-box',
    display: 'grid',
    gridTemplateColumns: `repeat(${cols}, 1fr)`,
    padding: `0 ${bleed}`,
    gap: gap,
    pointerEvents: 'none',
    userSelect: 'none',
  })

  for (let i = 0; i < parseInt(cols); i++) {
    const column = document.createElement('div')
    column.textContent = i + 1

    Object.assign(column.style, {
      color: `hsla(${color} / 0.4)`,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      background: `hsla(${color} / 0.05)`,
      borderInline: `1px solid hsla(${color} / 0.4)`,
    })

    container.append(column)
  }

  document.body.append(container)
}

/**
 * @function
 * @description An attempt to make the content not jump around on the screen while a page navigation is running
 */
export const createBarbaScrollPersist = (topOffset = 0) => {
  /**
   * polyfill: @virtualstate/navigation
   */
  if (!window.navigation) return

  window.navigation.addEventListener('navigate', e => {
    const scrollY = window.scrollY
    const { pathname } = new URL(e.destination.url)

    // bail if the new page is the old page (page load or changing query params)
    if (window.location.pathname === pathname) return

    const container = document.querySelector('main')

    // place the old page in the place it was,
    // so it's safe to scroll to the top
    container.style.position = 'fixed'
    container.style.width = '100vw'
    container.style.left = '0'
    container.style.top = `calc(${-scrollY}px + ${topOffset})`
  })
}

export const intersection = (el, callback, config = { threshold: [0] }) => {
  const observer = new IntersectionObserver(callback, config)

  if(el) {
    observer.observe(el)
  }

  return observer
}

export const progress = (() => {
  const multiplier = 0.5;
  let instances = [];

  window.ins = instances;

  let ticking = false;
  let raf = null;

  const start = () => {
    ticking = true;
    animate();
  };

  const pause = () => {
    ticking = false;
    cancelAnimationFrame(raf);
  };

  const destroy = () => {
    pause();
    instances = [];
  };

  const isAnimating = () => {
    return ticking;
  };

  const add = (entry) => {
    const { element, value } = entry;

    const valueAsFloat = parseFloat(value);

    const bounds = element.getBoundingClientRect();
    const startFromZero =
      bounds.y + bounds.height + scrollY >= 0 &&
      bounds.y + scrollY < innerHeight
        ? true
        : false;
    const initialProgressInScreen = Math.max(
      (innerHeight - bounds.y - scrollY) / (innerHeight + bounds.height),
      0
    );

    let startValue = 0;
    let endValue = 0;

    if (startFromZero) {
      startValue = 0;
    } else {
      startValue = valueAsFloat * -0.5;
    }
    endValue = valueAsFloat * 0.5;

    const instance = {
      element,
      startValue,
      endValue,
      range: endValue - startValue,
      currentValue: null,
      initialProgressInScreen,
      isInitialized: false,
    };

    instances.push(instance);

    if (!ticking) {
      start();
    }
  };

  const animate = () => {
    const { innerHeight } = window;
    let instancesLength = instances.length;
    let i = instancesLength;
    let autoPause;

    if (raf) {
      cancelAnimationFrame(raf);
    }

    if (ticking) {
      raf = requestAnimationFrame(animate);
    }

    while (i) {
      i--;
      const instance = instances[i];

      const {
        element,
        startValue,
        endValue,
        range,
        initialProgressInScreen,
        isInitialized,
      } = instance;
      const bounds = element.getBoundingClientRect();

      let progress = (innerHeight - bounds.y) / (innerHeight + bounds.height);
      // let progress = (innerHeight - bounds.y - endValue) / (innerHeight + bounds.height - range);

      //Offset progress
      let normalizedProgress =
        (progress - initialProgressInScreen) / (1 - initialProgressInScreen);

      if (normalizedProgress > 1) normalizedProgress = 1;
      if (normalizedProgress < 0) normalizedProgress = 0;

      if (normalizedProgress === 0 && instance.currentValue === startValue)
        continue;
      if (normalizedProgress >= 1 && instance.currentValue === endValue)
        continue;

      // const easedProgress = easeInOutCubic(normalizedProgress);
      const targetValue =
        (endValue - startValue) * normalizedProgress + startValue;
      const delta = targetValue - instance.currentValue;
      const velocity = Math.abs(delta) < 0.1 ? 0 : delta * multiplier;

      instance.currentValue += velocity;
      instance.currentValue = parseFloat(instance.currentValue.toFixed(3));

      // autoPause = false;

      if (Math.abs(delta) < 0.1 || !isInitialized) {
        instance.currentValue = parseFloat(targetValue.toFixed(3));
        instance.isInitialized = true;
        // autoPause = true;
      }

      const transformString = `translate3d(0, ${instance.currentValue}px,0)`;

      setTransform(element, transformString);
    }

    if (autoPause) {
      // console.log('Auto pausing');
      pause();
    }
  };

  const setTransform = (element, transform) => {
    element.style.transform = transform;
  };

  return {
    start,
    pause,
    destroy,
    isAnimating,
    add,
  };
})();
