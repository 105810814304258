import { useRefs } from "./../framework";

export default ref => {  
  if (!ref.countrySelector) return;

  ref.countrySelector.forEach(el => {
    const refs = useRefs({ root: el, namespaced: true });
    const input = el.querySelector(
      'input[name="locale_code"], input[name="country_code"]'
    );

    const handleOptionClick = e => {
      e.preventDefault();
      e.stopPropagation();

      input.value = e.currentTarget.dataset.value;
      refs.countryForm.submit();
    };

    const handleBtnClick = () => {
      const expanded = refs.btn.getAttribute("aria-expanded") === "true";
      refs.btn.setAttribute("aria-expanded", !expanded);

      refs.options.classList.toggle("hidden");
    };

    refs.option.forEach(option => {
      option.addEventListener("click", handleOptionClick);
    });

    refs.btn.addEventListener("click", handleBtnClick);
  });
};
