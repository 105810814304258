import embla from "./embla";
export default ref => {
  if (!ref.productRecommendations) return

  ref.productRecommendations.forEach(async el => {
    const { productId, url, sectionId, limit } = el.dataset

    try {
      const fetchUrl = new URL(url, window.location.origin)
      fetchUrl.searchParams.set('section_id', sectionId)
      fetchUrl.searchParams.set('product_id', productId)
      fetchUrl.searchParams.set('limit', limit)
      fetchUrl.searchParams.set('intent', 'related')

      const res = await fetch(fetchUrl.href);
      const text = await res.text();

      const parser = new DOMParser()
      const dom = parser.parseFromString(text, 'text/html')
      const dummy = el.querySelector('[data-dummy-content]')

      if (dummy) dummy.remove()

      el.append(dom.getElementById('shopify-section-product-recommendations-data'))

      embla(null, { root: el });

    } catch (error) {
      console.log('could not load recommendations', error)
    }

  })
}