import { gsap } from "gsap";
import { useRefs } from "./../framework";

export const revealItems = (ref) => {
  if (!ref.animateRevealItems) return;

  /**
   * usage:
   * <div data-ref="animate-items">
   *  <div data-ref-animate-items="items"></div>
   *  <div data-ref-animate-items="items"></div>
   *  <div data-ref-animate-items="items"></div>
   * </div>
   */

  function callback(entries) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;

      const { stagger, delay } = entry.target.dataset;

      const refs = useRefs({
        root: entry.target,
        namespaced: true,
        asArray: true,
      });

      if (refs.items) {
        gsap.to(refs.items, {
          y: "-100%",
          duration: 1.2,
          stagger: parseFloat(stagger || 0.1),
          ease: "expo.inOut",
          // delay: parseFloat(delay || 0.4),
        });
      }

      entry.target.style.opacity = 1;

      observer.unobserve(entry.target);
    });
  }

  const observer = new IntersectionObserver(callback, { threshold: 0 });

  ref.animateRevealItems.forEach((parent) => {
    // parent.style.opacity = 0;
    observer.observe(parent);
  });

  return () => {
    observer.disconnect();
  };
};

export const items = (ref) => {
  if (!ref.animateItems) return;

  /**
   * usage:
   * <div data-ref="animate-items">
   *  <div data-ref-animate-items="items"></div>
   *  <div data-ref-animate-items="items"></div>
   *  <div data-ref-animate-items="items"></div>
   * </div>
   */

  function callback(entries) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;

      const { stagger, delay } = entry.target.dataset;

      const refs = useRefs({
        root: entry.target,
        namespaced: true,
        asArray: true,
      });

      if (refs.items) {
        gsap.from(refs.items, {
          y: "50px",
          opacity: 0,
          stagger: parseFloat(stagger || 0.05),
          duration: 1,
          // delay: parseFloat(delay || 0.2),
        });
      }

      entry.target.style.opacity = 1;

      observer.unobserve(entry.target);
    });
  }

  const observer = new IntersectionObserver(callback, { threshold: 0 });

  ref.animateItems.forEach((parent) => {
    parent.style.opacity = 0;
    observer.observe(parent);
  });

  return () => {
    observer.disconnect();
  };
};

export const fadeIn = (ref) => {
  if (!ref.animateFadeIn) return;

  /**
   * usage:
   * <div data-ref="animate-fade-in"></div>
   * <div data-ref="animate-fade-in" data-x="0px"></div>
   * <div data-ref="animate-fade-in" data-y="50px"></div>
   * <div data-ref="animate-fade-in" data-delay="0.4"></div>
   */

  function callback(entries) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;

      const { delay, x, y } = entry.target.dataset;

      gsap.from(entry.target, {
        opacity: 0,
        x: x || "0px",
        y: y || "50px",
        duration: 0.8,
        delay: parseFloat(delay || 0.4),
      });

      observer.unobserve(entry.target);
    });
  }

  const observer = new IntersectionObserver(callback, { threshold: 0 });

  ref.animateFadeIn.forEach((element) => {
    observer.observe(element);
  });

  return () => {
    observer.disconnect();
  };
};

export const scaleUp = (ref) => {
  if (!ref.animateScaleUp) return;
  
  function callback(entries) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;

      let initial = entry.target.getBoundingClientRect();
      let scale = window.innerWidth / initial.width

      const { delay } = entry.target.dataset;

      gsap.to(entry.target, {
        scale: scale,
        duration: 1,
        delay: parseFloat(delay || 0.8),
        ease: "expo.out",
      });

      observer.unobserve(entry.target);
    });
  }

  const observer = new IntersectionObserver(callback, { threshold: 0 });

  ref.animateScaleUp.forEach((element) => {
    observer.observe(element);
  });

  return () => {
    observer.disconnect();
  };
};

export const rollUp = (ref) => {
  if (!ref.animateRollUp) return;
  
  function callback(entries) {
    entries.forEach((entry) => {
      if (!entry.isIntersecting) return;

      const { delay } = entry.target.dataset;

      gsap.to(entry.target, {
        y: "-100%",
        duration: 1.2,
        delay: parseFloat(delay || 0.4),
        ease: "expo.inOut",
      });

      observer.unobserve(entry.target);
    });
  }

  const observer = new IntersectionObserver(callback, { threshold: 0 });

  ref.animateRollUp.forEach((element) => {
    observer.observe(element);
  });

  return () => {
    observer.disconnect();
  };
};
