const duration = 200

export default {
  async leave({ current }) {
    return new Promise((resolve) => {
      current.container.style.transition = `all ${duration}ms`
      current.container.style.opacity = 0
      
      setTimeout(resolve, duration)
    })
  },
  
  async enter({ next }) {
    return new Promise((resolve) => {
      next.container.style.opacity = 0
      
      setTimeout(() => {
        next.container.style.transition = `all ${duration}ms`
        next.container.style.opacity = 1
  
        setTimeout(resolve, duration)
      }, duration + 50)
    })
  },
}