import "vite/modulepreload-polyfill";
import "@virtualstate/navigation/polyfill";
import barba from "@barba/core";
import {
  useHydrate,
  useRefs,
  useTransition,
  createDevGrid,
  createBarbaScrollPersist,
} from "~/framework";
import * as pageTransitions from "~/transitions";
import { globals, components, componentsAfterPageTransition } from "~/components";
import useALaCart from "~/modules/a-la-cart";

// visit ?grid to show visual grid
createDevGrid({
  cols: 12,
  bleed: "var(--grid-bleed)",
  gap: "var(--grid-gap)",
});

let refs = useRefs({ asArray: true });
const globalComponents = useHydrate(globals);
const pageComponents = useHydrate(components);
const pageComponentsAfterTransition = useHydrate(componentsAfterPageTransition);

// persist scroll position across navigation
createBarbaScrollPersist(refs.announcementBar ? "0rem" : '0px');

// used for removing all "old" event listeners after page shift
// note: this is maybe only necessary when listening on window-events?
//       browsers seems to be smart enough to removeEventListeners automatically
//       when an element is removed from the dom(?)
let abortController = null;
const refreshAbortController = () => {
  abortController?.abort();
  abortController = new AbortController();
};

function runExternalModules(container = document.body) {
  useALaCart({
    root: container,
    useBarbaNavigation: true,
    drawerContainer: "[data-cart-drawer-content]",
    cartSectionSelector: "[data-shopify-content]",
  });
}

barba.init({
  debug: location.origin.includes("127.0.0.1"),
  prevent: () => (window as any).Shopify.designMode,
  prefetchIgnore: "/cart",
  cacheIgnore: "/cart",
  transitions: useTransition({
    page: pageTransitions,
    global: {
      once() {
        refreshAbortController();
        globalComponents.hydrate(refs);
        pageComponents.hydrate(refs, { signal: abortController.signal });
        pageComponentsAfterTransition.hydrate(refs, { signal: abortController.signal });
        runExternalModules(document.body);
      },
      before() {
        window.dispatchEvent(new CustomEvent("window.navigation"));
      },
      leave() {
        refreshAbortController();
      },
      beforeEnter({ current }) {
        refs = useRefs({ exclude: current.container, asArray: true });
        pageComponents.hydrate(refs, { signal: abortController.signal });
      },
      enter({ current, next }) {
        refs = useRefs({ exclude: current.container, asArray: true });
        pageComponentsAfterTransition.hydrate(refs, { signal: abortController.signal });
        runExternalModules(next.container);
      },
    },
  }),
});
