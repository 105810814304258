

export default (ref) => {
  if (!ref.dropdown) return;

  ref.dropdown.forEach(el => {
    const dropdownButton = el.querySelector('#dropdownButton');
    const dropdownMenu = el.querySelector('#dropdownMenu');

    dropdownButton.addEventListener('click', function () {
      dropdownMenu.classList.toggle('hidden');
    });

    const handleDocumentClick = (e) => {
      const isClickInside = dropdownButton.contains(e.target) || dropdownMenu.contains(e.target);

      if (!isClickInside) {
        dropdownMenu.classList.add('hidden');
      }
    }

    document.addEventListener('click', handleDocumentClick);

    // const dropdownButton = document.getElementById('dropdownButton');
    // const dropdownMenu = document.getElementById('dropdownMenu');
    // const dropdownItems = dropdownMenu.querySelectorAll('a');

    // // Toggle dropdown menu visibility
    // dropdownButton.addEventListener('click', function () {
    //   dropdownMenu.classList.toggle('hidden');
    // });

    // // Handle item click to update button label and close menu
    // dropdownItems.forEach(item => {
    //   item.addEventListener('click', function (event) {
    //     event.preventDefault(); // Prevent default link behavior
    //     dropdownButton.innerHTML = item.textContent + ' ' + dropdownButton.querySelector('svg').outerHTML;
    //     dropdownMenu.classList.add('hidden'); // Hide the dropdown menu
    //   });
    // });

    // // Hide dropdown when clicking outside
    // document.addEventListener('click', function (event) {
    //   const isClickInside = dropdownButton.contains(event.target) || dropdownMenu.contains(event.target);
    //   if (!isClickInside) {
    //     dropdownMenu.classList.add('hidden');
    //   }
    // });
  })
}