export const progress = (() => {
  const multiplier = .1;
  let instances = [];
  
  let ticking = false;
  let raf = null;

  const start = () => {
    ticking = true;
    animate();
  }

  const pause = () => {
    ticking = false;
    cancelAnimationFrame(raf);
  }

  const destroy = () => {
    pause();
    instances = [];
  }

  const isAnimating = () => {
    return ticking;
  }

  const add = (entry) => {
    const { element, value, xOffset = 0 } = entry;

    const valueAsFloat = parseFloat(value);

    const bounds = element.getBoundingClientRect();
    const startFromZero = (bounds.y + bounds.height + scrollY >= 0 && bounds.y + scrollY < innerHeight) ? true : false;
    const initialProgressInScreen = Math.max( (innerHeight - bounds.y - scrollY) / (innerHeight + bounds.height), 0);

    let startValue = 0;
    let endValue = 0;

    if(startFromZero) {
      startValue = 0;
    } else {
      startValue = valueAsFloat * -0.5;
    }
    endValue = valueAsFloat * 0.5;

    const instance = {
      element,
      startValue,
      endValue,
      range: endValue - startValue,
      currentValue: null,
      initialProgressInScreen,
      isInitialized: false,
      xOffset // allows for using the elements transformX
    }

    instances.push(instance);

    if(!ticking) {
      start();
    }
  }

  const animate = () => {

    const { innerHeight } = window;
    let instancesLength = instances.length;
    let i = instancesLength;
    let autoPause;

    if(raf) {
      cancelAnimationFrame(raf);
    }
    
    if(ticking) {
      raf = requestAnimationFrame(animate);
    }

    while(i) {
      i --;
      const instance = instances[i];

      const { element, startValue, endValue, range, initialProgressInScreen, isInitialized, xOffset } = instance;
      const bounds = element.getBoundingClientRect();

      let progress = (innerHeight - bounds.y) / (innerHeight + bounds.height);
      let normalizedProgress =  (progress - initialProgressInScreen) /  (1 - initialProgressInScreen);

      if(normalizedProgress > 1) normalizedProgress = 1;
      if(normalizedProgress < 0) normalizedProgress = 0;

      if(normalizedProgress === 0 && instance.currentValue === startValue) continue;
      if(normalizedProgress >= 1 && instance.currentValue === endValue) continue;

      const targetValue = (endValue - startValue) * normalizedProgress + startValue;
      const delta = targetValue - instance.currentValue;
      const velocity = Math.abs(delta) < 0.1 ? 0 : delta * multiplier;

      instance.currentValue += velocity;
      instance.currentValue = parseFloat(instance.currentValue.toFixed(3));
      
      if(Math.abs(delta) < 0.1 || !isInitialized) {
        instance.currentValue = parseFloat(targetValue.toFixed(3));
        instance.isInitialized = true;
      }

      const transformString = `translate3d(${xOffset}, ${instance.currentValue}px,0)`;
      
      element.style.transform = transformString;
    }
  }


  return {
    start,
    destroy,
    isAnimating,
    add
  };

})();