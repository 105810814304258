import parallax from "./parallax";

export default ref => {
  if (!ref.productComplementary) return

  ref.productComplementary.forEach(async el => {
    const { productId, url, sectionId } = el.dataset

    try {
      const fetchUrl = new URL(url, window.location.origin)
      fetchUrl.searchParams.set('section_id', sectionId)
      fetchUrl.searchParams.set('product_id', productId)
      fetchUrl.searchParams.set('intent', 'complementary')

      const res = await fetch(fetchUrl.href);
      const text = await res.text();

      const parser = new DOMParser()
      const dom = parser.parseFromString(text, 'text/html')

      const dummy = el.querySelector('[data-dummy-complementary-content]')

      if (dummy) dummy.remove()

      const section = dom.getElementById('shopify-section-product-complementary-data')
      const productList = section.querySelector('.js-complementary-products-list')

      if(!productList) {
        el.remove()
      } else {
        el.insertAdjacentHTML('beforeend', productList.innerHTML)
      }

      parallax(el);

    } catch (error) {
      console.log('could not load recommendations', error)
    }
  })
}