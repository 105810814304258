import { setTransition } from './../framework'

export default ref => {
  if(!ref.setTransition) return

  ref.setTransition.forEach(element => {
    element.addEventListener('click', () => {
      setTransition(element.dataset.transition, window.scrollY)
  })
  })
}