import { useRefs } from "./../framework";
import { gsap } from "gsap";

export default (ref) => {
  if (!ref.filterForm) return;

  let activeFilterGroup = null;

  const refs = useRefs({ root: ref.filterForm[0], namespaced: true });
  const filterHeadings = refs.groupHeading ? [].concat(refs.groupHeading) : [];

  const handleHeadingClick = (e) => {
    const filterGroup = e.currentTarget.nextElementSibling;
    const filterGroupName = e.currentTarget.dataset.label;

    filterGroup.classList.toggle("hidden");
    activeFilterGroup = filterGroup;

    setOverlayTitle(filterGroupName);
  };

  const setOverlayTitle = (title) => {
    const overlay = ref.overlay.find((el) => el.dataset.type == "filters");
    const titleEl = overlay.querySelector('[data-ref="overlay-title"]');
    const backBtn = overlay.querySelector('[data-ref="overlay-back"]');
    const defaultTitle = titleEl.dataset.defaultTitle;

    if (!title) {
      titleEl.textContent = defaultTitle;
      backBtn.classList.add("hidden");

      ref.filterTags[0].classList.remove("hidden");

      return;
    }

    backBtn.classList.remove("hidden");
    ref.filterTags[0].classList.add("hidden");
    titleEl.textContent = title;
  };

  const handleBackClick = () => {
    activeFilterGroup.classList.add("hidden");
    activeFilterGroup = null;

    const checked = getActiveFilters();

    setOverlayTitle(activeFilterGroup);
    createFilterTags(checked);
  };

  const getActiveFilters = () => {
    const checkedInputs = {};
    const inputs = ref.filterForm[0].querySelectorAll("input:checked");

    inputs.forEach((input) => {
      if (!checkedInputs[input.name]) {
        checkedInputs[input.name] = [];
      }
      checkedInputs[input.name].push(input.value);
    });

    return checkedInputs;
  };

  const createFilterTags = (values) => {
    const checked = getActiveFilters();

    let activeFilters = values ?? checked;

    const tagContainer = ref.filterTagContainer[0];
    const noFilters = JSON.stringify(activeFilters) === "{}";

    ref.filterTags[0].classList.toggle("opacity-0", noFilters);

    // Clear existing tags
    tagContainer.innerHTML = "";

    for (const filterKey in activeFilters) {
      activeFilters[filterKey].forEach((filterValue) => {
        const tag = document.createElement("div");
        const label = getTagLabel(filterKey, filterValue);

        tag.classList.add(
          "px-2",
          "py-1",
          "flex",
          "items-center",
          "border",
          "border-black/15",
          "text-s",
          "mr-2",
        );
        tag.dataset.filterKey = filterKey;
        tag.dataset.filterValue = filterValue;
        tag.innerHTML = `<span class="flex gap-3 items-center cursor-pointer">
        ${label} 
        <span class="flex w-5 h-5 ml-3"><svg width="100%" height="100%" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M15 5L5 15" stroke="black" stroke-linecap="square" stroke-linejoin="round"/>
          <path d="M5 5L15 15" stroke="black" stroke-linecap="square" stroke-linejoin="round"/>
          </svg>
        </span></span>`;

        // Event listener to remove filter
        tag.addEventListener("click", () => {
          removeFilter(tag, filterKey, filterValue);
        });

        tagContainer.appendChild(tag);
      });
    }
  };

  const getTagLabel = (key, value) => {
    const inputs = ref.filterForm[0].querySelectorAll("input");
    const input = [...inputs].find(
      (input) => input.name == key && input.value == value,
    );

    // Return the text content of the label
    return input.previousElementSibling.textContent;
  };

  const setFilterCount = () => {
    const activeFilters = getActiveFilters();
    let filterCount = 0;

    for (const filterKey in activeFilters) {
      filterCount += activeFilters[filterKey].length;
    }

    if (filterCount == 0) {
      ref.filterCounter[0].textContent = "+";
    } else {
      ref.filterCounter[0].textContent = `(${filterCount})`;
    }
  };

  const setProductCount = (count) => {
    ref.productCounter[0].textContent = count;
  };

  const removeFilter = (element, filterKey, filterValue) => {
    const inputs = ref.filterForm[0].querySelectorAll("input:checked");

    inputs.forEach((input) => {
      if (input.value == filterValue && input.name == filterKey) {
        input.checked = false;
      }
    });

    if (inputs.length == 1) {
      ref.filterTags[0].classList.add("hidden");
    }

    element.remove();
    handleChange();
  };

  const setGroupCount = () => {
    if (!refs.groupSection) return;

    refs.groupSection.forEach((el) => {
      let section = el.dataset.section;
      let active = el.querySelectorAll("input:checked");
      let heading = refs.groupHeading.find(
        (heading) => heading.dataset.label == section,
      );

      heading.querySelector(".count").textContent = active.length
        ? `(${active.length})`
        : "";
    });
  };

  const handleChange = () => {
    applyFilters();
    setFilterCount();
    setGroupCount();
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const applyFilters = async () => {
    const form = ref.filterForm[0];
    const formData = new FormData(form);
    const baseUrl = window.location.origin + window.location.pathname;
    const currentParams = new URLSearchParams(window.location.search);
    const newParams = new URLSearchParams();

    const currentSorting = currentParams.get("sort_by");
    const currentSearchQuery = currentParams.get("q");

    if (currentSorting) {
      newParams.append("sort_by", currentSorting);
    }

    if (currentSearchQuery) {
      newParams.append("q", currentSearchQuery);
    }

    formData.forEach((value, key) => {
      if (!value) return;
      newParams.append(key, value.toString());
    });

    const fetchUrl = `${baseUrl}?${newParams.toString()}`;

    history.pushState(null, "", fetchUrl);

    try {
      const response = await fetch(fetchUrl);
      const data = await response.text();

      var parser = new DOMParser();
      var doc = parser.parseFromString(data, "text/html");
      var newProducts = doc.querySelectorAll("[data-ref='product-card']");
      var productCount = doc.querySelector("[data-ref='product-count']");
      var productList = document.getElementById("product-list");

      productList.innerHTML = "";

      newProducts.forEach((product) => {
        productList.appendChild(product);
      });

      if (ref.loadMore) {
        ref.loadMore[0].classList.add("hidden");
      }

      setProductCount(productCount.textContent);

      if (newProducts.length) {
        gsap.from(newProducts, {
          y: 0,
          opacity: 0,
          duration: 1,
        });
      }
    } catch (error) {
      console.error("Error fetching the product grid:", error);
    }
  };

  filterHeadings.forEach((el) => {
    el.addEventListener("click", handleHeadingClick);
  });

  ref.overlayBack.forEach((el) => {
    el.addEventListener("click", handleBackClick);
  });

  ref.filterClear.forEach((el) => {
    el.addEventListener("click", () => {
      document.body.style.overflowY = "auto";
    });
  });

  ref.filterForm[0].addEventListener("change", handleChange);
  ref.filterForm[0].addEventListener("submit", handleSubmit);

  createFilterTags(null);
  setFilterCount();
  setGroupCount();
};
