export default ref => {
  if (!ref.accordionGroup) return;

  ref.accordionGroup.forEach(el => {
    const accordion_buttons = el.querySelectorAll('[data-ref="accordion-btn"]');
    const contents = el.querySelectorAll('[data-ref="accordion-content"]');

    accordion_buttons.forEach(button => {
      button.addEventListener("click", function() {
        const contentId = this.getAttribute("aria-controls");
        const expanded = this.getAttribute("aria-expanded") === "true";

        [...contents].forEach(item => {
          if (item.id === contentId) {
            item.classList.toggle("grid-rows-[1fr]", !expanded);
            item.classList.toggle("grid-rows-[0fr]", expanded);
            item.classList.toggle("opacity-100", !expanded);
            item.classList.toggle("opacity-0", expanded);
          } else {
            item.classList.toggle("grid-rows-[1fr]", false);
            item.classList.toggle("opacity-100", false);
            item.classList.toggle("grid-rows-[0fr]", true);
            item.classList.toggle("opacity-0", true);
          }
        });

        accordion_buttons.forEach(btn => {
          if (btn == this) {
            btn.setAttribute("aria-expanded", !expanded);
          } else {
            btn.setAttribute("aria-expanded", false);
          }
        });
      });
    });
  });
};
