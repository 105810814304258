import { gsap } from "gsap";

export default ref => {
  if (!ref.loadMore) return;

  let collectionHandle = ref.loadMore[0].dataset.collection;
  let collectionCount = ref.loadMore[0].dataset.collectionCount;
  let label = ref.loadMore[0].querySelector("[data-ref='btn-label']")
  
  const handleLoadMore = () => {
    let currentPage = ref.loadMore[0].dataset.page;
    
    label.innerText = 'Loading...';

    addProducts(currentPage)
  };

  const addProducts = (currentPage) => {
    let nextPage = parseInt(currentPage) + 1;
    let fetchUrl = `/collections/${collectionHandle}?page=${currentPage}`

    fetch(fetchUrl)
      .then(response => response.text())
      .then(data => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(data, "text/html");
        var newProducts = doc.querySelectorAll("[data-ref='product-card']");
        var productList = document.getElementById("product-list");

        newProducts.forEach(product => {
          productList.appendChild(product);
        });

        if (newProducts.length) {
          gsap.from(newProducts, {
            y: 0,
            opacity: 0,
            duration: 1,
          });
        }

        ref.loadMore[0].dataset.page = nextPage;
        label.innerText = 'Load More';

        checkLoadMore(productList)

        var newUrl = new URL(window.location.href);

        newUrl.searchParams.set('p', currentPage);
        history.pushState(null, '', newUrl);

      })
      .catch(error => {
        console.error("Error loading more products:", error);
        label.innerText = "Load More";
      });
  }

  const checkLoadMore = (productList) => {
    const productCards = productList.querySelectorAll("[data-ref='product-card']");
    if(productCards.length == collectionCount) {
      ref.loadMore[0].classList.add("hidden")
    }
  }

  const checkUrl = () => {
    var urlParams = new URLSearchParams(window.location.search);
    var page = urlParams.get('p');

    if(!!page && parseInt(page) > 1)  {
      for (var i = 1; i < parseInt(page); i++) {
        let page = i + 1
        addProducts(page)
      }
    }
  }

  const init = () => {
    ref.loadMore[0].addEventListener("click", handleLoadMore);
    checkUrl();
  }

  init();

  return () => {
    if (ref.loadMore[0]) ref.loadMore[0].removeEventListener("click", handleLoadMore);
  };
};
