import { useRefs, intersection } from "./../framework";

export default (ref) => {
  if (!ref.instagram) return;

  ref.instagram.forEach(el => {
    
    const { image } = useRefs(el);
    const images = image ? [].concat(image) : [];
    const { fetchUrl } = el.dataset;
    let observer
  
    const init = () => {
      observer = intersection(el, handleIntersection, {
        threshold: [0],
        rootMargin: `0px 0px 1000px 0px`,
      })
    }
  
    const handleIntersection = ([entry]) => {
      if (entry.isIntersecting) {
        getInstagramData();
      }
    }
  
    const getInstagramData = () => {
      if(!fetchUrl) return;
  
      const imageData = fetch(fetchUrl)
        .then((res) => res.json())
        .then(({ data }) => data);
  
      imageData.then((data) => {
        images.forEach((img,idx) => {
  
          if(!data) return;
  
          img.src = data[idx]?.images?.standard_resolution?.url;
          img.alt = data[idx]?.caption?.text;
          img.srcset = `${data[idx]?.images?.standard_resolution?.url} 25w`;
          img.classList.remove('opacity-0');
        });
      });
    };
  
    init();
  
  });
};
