import { useRefs } from "./../framework";

export default ref => {
  if (!ref.quantitySelector) return;

  ref.quantitySelector.forEach(el => {
    const refs = useRefs({ root: el, namespaced: true });

    function increaseValue(by) {
      refs.input.value = parseInt(refs.input.value) + by;
      refs.input.dispatchEvent(new Event("input", { bubbles: true }));
      refs.input.dispatchEvent(new Event("change", { bubbles: true }));

      if (parseInt(refs.input.value) === 1) {
        refs.decrease.setAttribute("disabled", true);
      } else {
        refs.decrease.removeAttribute("disabled");
      }
    }

    refs.decrease.addEventListener("click", () => increaseValue(-1));
    refs.increase.addEventListener("click", () => increaseValue(1));
  });
};
