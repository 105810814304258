import { useRefs } from "./../framework";

export default ref  => {
  if (!ref.embedVideo) return;

  ref.embedVideo.forEach(el => {
    const refs = useRefs({ root: el, namespaced: true });

    const handlePlayClick = (e) => {
      let iframe = el.getElementsByTagName('iframe');
      let videoSrc = iframe[0].getAttribute('src');
      
      // Check if the video already has autoplay enabled
      if (videoSrc.indexOf('autoplay=1') === -1) {
        iframe[0].setAttribute('src', videoSrc + (videoSrc.includes('?') ? '&' : '?') + 'autoplay=1');
      }

      setTimeout(() => {
        refs.poster.classList.add('opacity-0')
        refs.poster.classList.add('pointer-events-none')
      }, 250)
    }

    refs.poster.addEventListener('click', handlePlayClick);
  });
} 
