import EmblaCarousel from "embla-carousel";
import { breakpoints } from "./../framework";
import { addPrevNextBtnsClickHandlers } from "./../modules/embla-buttons.js";

// accepts second argument in order to en initialized anywhere:
/*

// my-component.js
import embla from './components/embla'
embla(null, document.querySelector('.my-component')) <- only look in this element

*/
export default (ref, { root = document } = {}) => {
  const emblas = Array.from(root.querySelectorAll(".embla"));
  const isMobile = () => window.innerWidth < breakpoints.md;

  if (!emblas.length) return;

  emblas.map((emblaContainer: HTMLElement) => {
    const viewport = emblaContainer.querySelector(
      ".embla__viewport"
    ) as HTMLElement;
    const prevButton = emblaContainer.querySelector(
      ".embla__prev"
    ) as HTMLElement;
    const nextButton = emblaContainer.querySelector(
      ".embla__next"
    ) as HTMLElement;
    const slideTo = isMobile() ? 1 : 4;

    if (!viewport) return;

    const embla = EmblaCarousel(viewport, {
      loop: false,
      skipSnaps: false,
      dragFree: false,
      slidesToScroll: slideTo
    });
    
    const removePrevNextBtnsClickHandlers = addPrevNextBtnsClickHandlers(
      embla,
      prevButton,
      nextButton
    );
    
    function logSlidesInView () {
      const slides = embla.slideNodes();
      const videoSlides = slides.filter(slide => !!slide.querySelector('video'))
      
      if(!videoSlides.length) return;

      const indexesInView =  embla.slidesInView();
      const slidesInView =  slides.filter((slide, idx) => indexesInView.includes(idx));

      videoSlides.forEach(videoSlide => {
        const video = videoSlide.querySelector('video')
        if(slidesInView.includes(videoSlide)) {
          video.play()
        } else {          
          video.pause()
          video.currentTime = 0;
        }
      })
    }

    embla.on('slidesInView', logSlidesInView)
    embla.on("destroy", removePrevNextBtnsClickHandlers);
  });
};
