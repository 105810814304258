export default ref => {
  if (!ref.pdpGallery) return;

  const handleThumbClick = e => {
    const selectedIdx = e.currentTarget.dataset.index;

    ref.pdpThumb.forEach(el => {
      const img = el.getElementsByTagName('img')[0];
      img.classList.toggle("border", el.dataset.index == selectedIdx);
    });

    ref.pdpPreview.forEach(el => {
      const video = el.querySelector('video')
      const isSelected = el.dataset.index == selectedIdx

      el.classList.toggle("opacity-0", !isSelected);
      
      if(video) {
        if(isSelected) {
          video.play()
        } else {
          video.pause()
          video.currentTime = 0;
        }
      }

    });
  };

  ref.pdpThumb.forEach(el => {
    el.addEventListener("click", handleThumbClick);
  });

  ref.pdpPreview.forEach(el => {
    const video = el.querySelector('video')
  })
};
